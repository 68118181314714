<template>
  <div class="appointments appointment">
    <div v-if="loading" class="loading-wrap" style="padding: 20px 0 0 0">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>
      <div v-if="appointment">
        <h1 style="margin-bottom:20px">Ihr Termin</h1>

        <div v-if="appointment.status == 'canceled'">
          <p class="alert alert-info">Der Termin wurde erfolgreich storniert.</p>
        </div>
        <div v-else>
          <div style="border: 2px dashed #f1f1f1;padding:20px;margin-bottom:20px">
            <h3 style="color: #a10e2f;margin-top:0;margin-bottom:10px">Stornierung</h3>
            <p style="margin-top:0">Bitte klicken Sie auf den folgenden Button um den Termin zu stornieren.</p>

            <p style="margin-bottom:0">
              <a @click="cancel_appointment" class="button button-red button-100">Termin jetzt stornieren ›</a>
            </p>
          </div>

          <div class="step-confirm">
            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="confirm-wrap">
                  <p class="head">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
                    </svg>
                    <span>Kategorie</span>
                  </p>
                  <p><span v-if="appointment.category">{{ appointment.category.name }}</span></p>
                </div>
              </div>
              <div class="col-12">
                <div class="confirm-wrap">
                  <p class="head">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-check" class="svg-inline--fa fa-user-check fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                      <path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z"></path>
                    </svg>
                    <span>Leistung</span>
                  </p>
                  <p><span v-if="appointment.service">{{ appointment.service.name }}</span></p>
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="confirm-wrap">
                  <p class="head">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-day" class="svg-inline--fa fa-calendar-day fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h96c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-96zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                    <span>Datum</span>
                  </p>
                  <p>{{ appointment.date_formatted }}</p>
                </div>
              </div>
              <div class="col-12">
                <div class="confirm-wrap">
                  <p class="head">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" class="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                    </svg>
                    <span>Uhrzeit</span>
                  </p>
                  <p>{{ appointment.time_formatted }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-else>
        <p style="margin-top:50px" class="alert alert-info">Es wurde leider kein Termin gefunden.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appointment',
  data () {
    return {
      loading: true,
      appointment: null
    }
  },
  methods: {
    get_data() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/appointments/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.appointment = response.data.appointment;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
    },
    cancel_appointment() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/appointments/'+this.$route.params.uuid, {
        status: "canceled"
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.$notify({
          title: "Termin erfolgreich storniert.",
          type: "success"
        });
        this.get_data();
      })
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
